import React from "react";

/**
 * KeyFeatureCard Component
 *
 * @param {string} heading - The heading of the card.
 * @param {string} icon - The icon source for the card.
 * @param {string} description - The description of the card.
 * @param {string} [headingClasses] - Optional additional classes for the heading.
 * @param {string} [descriptionClasses] - Optional additional classes for the description.
 * @returns {JSX.Element} - The KeyFeatureCard component.
 */
export const KeyFeatureCard = ({
  heading,
  icon,
  description,
  headingClasses,
  descriptionClasses,
}) => {
  return (
    <div className=" mx-2 rounded-2xl bg-[rgba(2,59,89,0.04)] p-6 lg:p-8 ">
      <img src={icon} alt={heading} className="h-24 w-24" loding="lazy" />

      <p
        className={`mt-8 text-xl font-semibold text-heading  ${
          headingClasses && headingClasses
        }`}
      >
        {heading}
      </p>
      <p
        className={`mt-4 text-base text-bodyText lg:mt-6  ${
          descriptionClasses && descriptionClasses
        } `}
      >
        {description}
      </p>
    </div>
  );
};

export default KeyFeatureCard;
