import React from "react";
import DataValidatorHeroSection from "../../components/dataValidator/dataValidatorHeroSection/DataValidatorHeroSection";
import UseCases from "../../components/dataValidator/useCases/UseCases";
import Advantages from "../../components/dataValidator/advantages/Advantages";
import DataValidatorFeatures from "../../components/dataValidator/dataValidatorFeatures/DataValidatorFeatures";

const DataValidatorPage = () => {
  return (
    <>
      <DataValidatorHeroSection />
      <DataValidatorFeatures />
      <UseCases />
      <Advantages />
    </>
  );
};

export default DataValidatorPage;
