import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { whyChooseUsCardData } from "../../../helper/cardsData/timeEfficientMockData";
import WhyChooseUsCard from "../../../common/whyChooseUsCard/WhyChooseUsCard";

export const WhyChooseYouUs = () => {
  return (
    <div className="why-choose-us-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Why Choose Us?" />
        <div className=" mt-8  grid  gap-8 md:grid-cols-2 lg:mt-12 lg:grid-cols-3 ">
          {whyChooseUsCardData.map((cardData) => {
            return (
              <WhyChooseUsCard
                key={cardData.heading}
                image={cardData.icon}
                heading={cardData.heading}
                description={cardData.description}
                descriptionClasses="lg:h-30 h-36"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseYouUs;
