import timeEfficientSimplifiedCodeIcon from "../../assets/timeEfficientSimplifiedCodeIcon.svg";
import timeEfficientStatusCodeIcon from "../../assets/timeEfficientStatusCodeIcon.svg";
import timeEfficientLegacyApiIcon from "../../assets/timeEfficientLegacyApiIcon.svg";
import timeEfficientWhyChooseUsPrecisionAndAccuracyIcon from "../../assets/timeEfficientWhyChooseUsPrecisionAndAccuracyIcon.svg";
import timeEfficientWhyChooseUsTimeEfficiencyIcon from "../../assets/timeEfficientWhyChooseUsTimeEfficiencyIcon.svg";
import timeEfficientWhyChooseUsUserFriendlyInterfaceIcon from "../../assets/timeEfficientWhyChooseUsUserFriendlyInterfaceIcon.svg";
import timeEfficientComprehensiveComparisonIcon from "../../assets/timeEfficientComprehensiveComparisonIcon.svg";
import timeEfficientUnifiedValidationIcon from "../../assets/timeEfficientUnifiedValidationIcon.svg";

export const timeEfficientKeyFeaturesCardData = [
  {
    heading: "Simplified Code Automation",
    icon: timeEfficientSimplifiedCodeIcon,
    description:
      "GemPyp offers a user-friendly, simplified code automation solution for API testing, eliminating the need for complex scripting. This allows users to focus on obtaining meaningful results rather than grappling with intricate code.",
  },
  {
    heading: "Legacy API Comparison",
    icon: timeEfficientLegacyApiIcon,
    description:
      "GemPyp specializes in comparing legacy API responses, ensuring a smooth transition from older versions to the latest ones. The platform minimizes the need for manual intervention, making the migration process efficient and reliable.",
  },
  {
    heading: "Status Code Analysis",
    icon: timeEfficientStatusCodeIcon,
    description:
      "Effortlessly analyze API health with GemPyp's status code analysis feature. Quickly identify potential issues and discrepancies in status codes, enabling a proactive approach to problem-solving and system optimization.",
  },
  {
    heading: "Comprehensive Comparison",
    icon: timeEfficientComprehensiveComparisonIcon,
    description:
      "GemPyp goes beyond basic response checks and facilitates thorough comparisons between legacy and latest API versions. Its robust capabilities cover a spectrum of factors, providing users with a comprehensive testing experience for enhanced reliability.",
  },
  {
    heading: "Unified Validation",
    icon: timeEfficientUnifiedValidationIcon,
    description:
      'GemPyp streamlines API migration testing with the "Compare All to legacy" feature, unifying validation of response codes, data types, key counts, record completeness, and key-value pairs for meticulous and accurate comparisons.',
  },
];

export const whyChooseUsCardData = [
  {
    heading: "1. Time Efficiency",
    icon: timeEfficientWhyChooseUsTimeEfficiencyIcon,
    description:
      "Streamline testing timelines with GemPyp's automation. Spend less time on repetitive tasks and more time on strategic testing and analysis.",
  },
  {
    heading: "2. Precision and Accuracy",
    icon: timeEfficientWhyChooseUsPrecisionAndAccuracyIcon,
    description:
      "Utilize GemPyp’s precise comparison algorithms for accurate testing results. Identify even the smallest discrepancies and address them proactively.",
  },
  {
    heading: "3. User-Friendly Interface",
    icon: timeEfficientWhyChooseUsUserFriendlyInterfaceIcon,
    description:
      "Navigate effortlessly with GemPyp's intuitive interface. No extensive training required—get started right away.",
  },
];

export const timeEfficientCodeSnippetText = `<testcase>
<name> Legacy Data Comparison with Current Data </name>
<category> Scenario Testcase </category>
<run-flag> Y </run-flag>
<api> https://gorest.co.in/public/v2/users </api>
<method> GET </method>
<legacy-api> https://gorest.co.in/public/v1/users </legacy-api>
<legacy-method> GET </legacy-method>
<legacy-expected-status-code> 200 </legacy-expected-status-code>
<key-check> keys are legacy.meta,legacy.data[0].id,legacy.data[0].name,
response[0].id,response[0].name</key-check>
<post-assertion> compare all legacy.data </post-assertion>
<TYPE> PYPREST </TYPE>
</testcase>`;
