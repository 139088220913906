import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import uiAutomationAdditionalOfferingsIcon from "../../../assets/uiAutomationAdditionalOfferingsIcon.svg";
import { additionalOfferingsCardsData } from "../../../helper/cardsData/uiAutomationPageMockData";

const AdditionalOfferingsCard = ({ icon, heading, isEven }) => {
  return (
    <div className="my-4 flex flex-col items-center gap-4 lg:my-0 lg:flex-row lg:odd:flex-row-reverse">
      <img src={icon} alt={heading} loading="lazy" />
      <p
        className={`text-center text-xl font-semibold  text-heading ${
          isEven ? "lg:text-right" : "lg:text-left"
        }`}
      >
        {heading}
      </p>
    </div>
  );
};

const AdditionalOfferings = () => {
  return (
    <div className="component-padding">
      <ComponentHeading heading="Additional Offerings" />

      {/* mobile view */}
      <div className="lg:hidden">
        <img
          className="mx-auto my-8"
          src={uiAutomationAdditionalOfferingsIcon}
          alt=" a green check"
          loading="lazy"
        />
        <div className="grid">
          {additionalOfferingsCardsData.map((item) => {
            return (
              <AdditionalOfferingsCard
                icon={item.icon}
                heading={item.heading}
              />
            );
          })}
        </div>
      </div>

      {/* desktop view */}
      <div className="relative hidden lg:block">
        <div className="mt-12 grid grid-cols-2 gap-40">
          {additionalOfferingsCardsData.map((item, index) => {
            return (
              <AdditionalOfferingsCard
                icon={item.icon}
                heading={item.heading}
                isEven={index % 2 === 0}
              />
            );
          })}
        </div>
        <div className="absolute left-1/2 top-1/2 -z-10 -translate-x-1/2 -translate-y-1/2 rounded-full border-8 border-dashed border-[#979797] p-24">
          <img
            src={uiAutomationAdditionalOfferingsIcon}
            alt="a green check"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalOfferings;
