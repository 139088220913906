import React from "react";
import PypRestHeroSection from "../../components/pypRestPage/pypRestHeroSection/PypRestHeroSection";
import Advantages from "../../components/pypRestPage/advantages/Advantages";
import AspectAddressed from "../../components/pypRestPage/aspectAddressed/AspectAddressed"


const PypRestPage = () => {
  return (
    <>
     <PypRestHeroSection />
     <Advantages />
     <AspectAddressed />
    </>
  );
};

export default PypRestPage;
