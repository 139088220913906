import React from "react";
import UiAutomationKeyFeatures from "../../components/uiAutomation/uiAutomationKeyFeatures/UiAutomationKeyFeatures";
import CodeSnippetForVariousScenarios from "../../components/uiAutomation/codeSnippetForVariousScenarios/CodeSnippetForVariousScenarios";
import UiAutomationHeroSection from "../../components/uiAutomation/uiAutomationHeroSection/UiAutomationHeroSection";
import UiAutomationWhyChooseUs from "../../components/uiAutomation/uiAutomationWhyChooseUs/UiAutomationWhyChooseUs";
import UiAutomationCodeSnippet from "../../components/uiAutomation/uiAutomationCodeSnippet/UiAutomationCodeSnippet";
import AdditionalOfferings from "../../components/uiAutomation/additionalOfferings/AdditionalOfferings";
const UiAutomationPage = () => {
  return (
    <>
      <UiAutomationHeroSection />
      <UiAutomationKeyFeatures />
      <UiAutomationCodeSnippet />
      <UiAutomationWhyChooseUs />
      <AdditionalOfferings />
      <CodeSnippetForVariousScenarios />
    </>
  );
};

export default UiAutomationPage;
