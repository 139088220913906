import React from "react";
import { gameChangerData } from "../../../helper/cardsData/homepageMockData";
import GameChangerCard from "./gameChangerCard/GameChangerCard";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import "./GameChanger.css";

const gameChanger = () => {
  return (
    <div className="game-changer-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Game Changer" />
        <div className=" mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:mt-12 lg:grid-cols-3 ">
          {gameChangerData.map((cardData) => {
            return (
              <GameChangerCard
                key={cardData.id}
                heading={cardData.heading}
                imageSource={cardData.img}
                description={cardData.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default gameChanger;
