import React from "react";
import "./AspectAddressedCard.css";

const AspectAddressCard = ({ id, heading, image, description }) => {
  return (
    <div key={id} className="pyp-rest-aspect-address-card-wrapper ">
      <div className="rounded-image-wrapper">
        <img
          src={image}
          alt={heading}
          className="aspect-[1.15/1] w-full bg-center object-cover"
        />
      </div>
      <div className="p-6">
        <p className=" h-12 text-lg font-semibold text-heading  lg:text-xl">
          {heading}
        </p>
        <p className="mt-4 h-36  text-bodyText lg:text-lg">{description}</p>
      </div>
    </div>
  );
};

export default AspectAddressCard;
