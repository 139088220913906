import React, { useRef,useEffect, useState } from "react";
import "./WhatUsersCanDo.css";
import { whatUsersCanDoData } from "../../../helper/cardsData/homepageMockData";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import FadeOnScroll from "../../../common/fadeOnScroll/FadeOnScroll";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { Dialog } from "primereact/dialog"; // Import the Dialog component from PrimeReact

const WhatUsersCanDo = () => {
  const [imageDialogIndex, setImageDialogIndex] = useState(0);
  const [visible, setVisible] = useState(false);

  const handleImageClick = (index) => {
    setImageDialogIndex(index); // Update the active index
    setVisible(true); // Open the dialog
  };
  const whatUsersCanDoRef = useRef(null);

  // const [isIntersecting, setIsIntersecting] = useState(false);

  // console.log("useEffect", isIntersecting);

  // useEffect(() => {
  //   if (!whatUsersCanDoRef.current) return;

  //   const scrollObserver = new IntersectionObserver(
  //     ([entry]) => {
  //       setIsIntersecting(entry.isIntersecting);
  //       console.log("obs", entry.isIntersecting);
  //     },
  //     {
  //       threshold: 0,
  //     },
  //   );

  //   scrollObserver.observe(whatUsersCanDoRef.current);

  //   return () => {
  //     if (whatUsersCanDoRef.current) {
  //       scrollObserver.unobserve(whatUsersCanDoRef.current);
  //     }
  //   };
  // }, []);
  return (
    <div ref={whatUsersCanDoRef}>
      <div className="sticky-background-wrapper " />
      <div className="what-users-can-do-wrapper component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading
          heading="What Users Can Do"
          extraHeadingClasses="text-white"
        />
        {whatUsersCanDoData.map((item,index) => {
          return (
            <React.Fragment key={item.id}>
              <FadeOnScroll>
                <div className="lg:flex lg:gap-x-8">
                  <img
                    className="mx-auto lg:flex-1"
                    src={item.image}
                    alt={item.heading}
                    loading="lazy"
                    onClick={() => handleImageClick(index)} // Add onClick event handler
                    style={{ cursor: 'pointer' }} // Add cursor pointer style
                  />
                  <div className="lg:my-auto lg:flex-1">
                    <p className="what-users-can-do-heading mt-8">
                      {item.heading}
                    </p>
                    <p className="what-users-can-do-description mt-6">
                      {item.description}
                    </p>
                    <div className="mt-6">
                      <Link to={item.link}>
                        <Button
                          label={item.buttonLabel}
                          className="base-button tertiary-button-white"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </FadeOnScroll>
            </React.Fragment>
          );
        })}
      </div>
      <Dialog
        header=""
        visible={visible}
        maximizable
        style={{ width: '60vw' }}
        onHide={() => setVisible(false)}
      >
        <div>
          <img
            src={whatUsersCanDoData[imageDialogIndex].image}
            alt={whatUsersCanDoData[imageDialogIndex].heading}
            loading="lazy"
            style={{ width: '100%' }}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default WhatUsersCanDo;
