import React, { useEffect, useRef, useState } from "react";
import "./ScrollImageTransitionCard.css";

/**
 * ScrollImageTransitionCard Component
 *
 * A React component that provides a scroll-triggered animation for its content.
 * The animation involves scaling and opacity transitions based on the scroll position.
 *
 * @component
 * @param {string} imageSource - The source URL for the image displayed in the component.
 * @param {string} imageAltText - The alternative text for the image.
 * @param {string} heading - The heading text displayed above the bullet points.
 * @param {string[]} bulletPoints - An array of strings representing the bullet points.
 * @param {boolean} isFirst - Indicates whether this instance is the first in the sequence
 * @param {boolean} isLast - Indicates whether this instance is the last in the sequence
 *
 * @returns {React.Component} - The ScrollImageTransitionCard component.
 */
const ScrollImageTransitionCard = ({
  imageSource,
  imageAltText,
  heading,
  bulletPoints,
  isFirst,
  isLast,
}) => {
  const wrapperRef = useRef(null);

  // these variables are used to know if the div has crossed the top 30% of screenHeight. And then manipulate the opacity and scaling.
  const [topCrossed, setTopCrossed] = useState(false);
  const [bottomCrossed, setBottomCrossed] = useState(false);

  useEffect(() => {
    const animationFunction = () => {
      const wrapper = wrapperRef.current;
      if (!wrapper) return;

      const screenHeight = window.innerHeight;
      const wrapperRect = wrapper.getBoundingClientRect();
      const topDistance = wrapperRect.top;
      const bottomDistance = screenHeight - wrapperRect.bottom;

      setTopCrossed(topDistance < screenHeight * 0.3);
      setBottomCrossed(bottomDistance > screenHeight * 0.7);
    };

    // run these expensive functions only in desktop mode
    if (window.innerWidth >= 1024) {
      window.addEventListener("scroll", animationFunction);
      // if window size is changes by the user, recalculate
      window.addEventListener("resize", animationFunction);
    }

    // Remove event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", animationFunction);
      window.removeEventListener("resize", animationFunction);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      className=" mt-8 lg:relative lg:mt-0 lg:grid lg:h-96  lg:grid-cols-2 lg:content-stretch lg:gap-36"
    >
      {/* absolute */}
      <div className="circular-ring-wrapper ">
        <div
          className={`circular-ring hidden lg:block ${
            topCrossed && "scale-150 !border-primaryColor p-[0.15rem]"
          }`}
        >
          {topCrossed && <div className="circular-ring-dot"></div>}
        </div>
      </div>
      <div
        className={` ${!isLast && "lg:-mb-96"} lg:flex  lg:justify-self-center`}
      >
        <img
          className={` aspect-auto rounded-[1.25rem] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.08)] lg:h-96 lg:transition-all lg:duration-300   ${
            !isLast && "lg:sticky lg:top-[30%]"
          } 
          ${
            ((topCrossed && bottomCrossed && !isLast) ||
              (!topCrossed && !bottomCrossed && !isFirst)) &&
            "lg:opacity-0"
          }
          ${
            bottomCrossed && !isLast
              ? "lg:scale-125"
              : isFirst || topCrossed
              ? "lg:scale-100"
              : "lg:scale-75"
          }`}
          src={imageSource}
          alt={imageAltText}
          loading="lazy"
        />
      </div>
      <div className="mt-6 lg:mt-0">
        <div className="lg:relative">
          <p
            className={`mb-6 text-xl font-semibold text-heading lg:text-2xl ${
              topCrossed && "lg:text-primaryColor"
            }`}
          >
            {heading}
          </p>
        </div>
        {bulletPoints.map((point) => {
          return (
            <div className="flex text-base text-bodyText lg:text-lg">
              <span className="mr-2">•</span>
              <p> {point}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScrollImageTransitionCard;
