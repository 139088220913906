import React from "react";
import "./UiAutomationWhyChooseUsCard.css";

export const UiAutomationWhyChooseUsCard = ({
  heading,
  image,
  description,
}) => {
  return (
    <div className="why-choose-you-card-wrapper">
      <div>
        <img
          src={image}
          alt={heading}
          className="h-full w-full  object-cover"
        />
      </div>
      <p className="text-xl font-semibold text-heading">{heading}</p>
      <p className="mt-4 text-bodyText ">{description}</p>
    </div>
  );
};

export default UiAutomationWhyChooseUsCard;
