import "./App.css";
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createHashRouter,
} from "react-router-dom";
import Homepage from "./pages/homePage/Homepage";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Header from "./common/header/Header";
import Footer from "./common/footer/Footer";
import ComingSoon from "./pages/comingSoonPage/ComingSoon";
import DataValidatorPage from "./pages/dataValidatorPage/DataValidatorPage";
import Pyprest from "./pages/pypRestPage/PypRestPage";
import DataComparison from "./pages/dataComparisonPage/DataComparisonPage";
import UiAutomationPage from "./pages/uiAutomationPage/UiAutomationPage";
import TimeEfficientPage from "./pages/timeEfficientPage/TimeEfficientPage";
import ScrollToTop from "./common/scrollToTop/ScrollToTop";

const router = createHashRouter([
  {
    element: <Root />,
    children: [
      { path: "/", element: <Homepage /> },
      { path: "/data-validator", element: <DataValidatorPage /> },
      { path: "/coming-soon", element: <ComingSoon /> },
      { path: "/pyp-rest", element: <Pyprest /> },
      { path: "/data-comparison", element: <DataComparison /> },
      { path: "/ui-automation", element: <UiAutomationPage /> },
      { path: "/time-efficient", element: <TimeEfficientPage /> },
      { path: "*", element: <ComingSoon /> },
    ],
  },
]);

function Root() {
  return (
    <PrimeReactProvider>
      <ScrollRestoration />
      <Header />
      <ScrollToTop />
      <Outlet />
      <Footer />
    </PrimeReactProvider>
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
