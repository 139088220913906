import AdvantagesCodeMaintainabilityIcon from "../../assets/advantagesCodeMaintainabilityIcon.svg";
import AdvantagesComprehensiveValidationIcon from "../../assets/advantagesComprehensiveValidationIcon.svg";
import AdvantagesEfficientAutomationIcon from "../../assets/advantagesEfficientAutomationIcon.svg";
import AdvantagesEnhancedReportingIcon from "../../assets/advantagesEnhancedReportingIcon.svg";
import AdvantagesFlexiableExecutionOptionIcon from "../../assets/advantagesFlexiableExecutionOptionIcon.svg";
import AspectAddressedCodeMaintenanceChallengesImage from "../../assets/aspectAddressedCodeMaintenanceChallengesImage.png";
import AspectAddressedSimplifiedAutomationComplexityImage from "../../assets/aspectAddressedSimplifiedAutomationComplexityImage.png";
import AspectAddressedValidationAccuracyImage from "../../assets/aspectAddressedValidationAccuracyImage.png";

export const advantagesCardData = [
  {
    icon: AdvantagesEfficientAutomationIcon,
    cardNumber: "1",
    heading: "Efficient Automation",
    description:
      "PypRest streamlines the process of automating REST API testing, allowing for faster and more efficient test case development and execution.",
  },
  {
    icon: AdvantagesCodeMaintainabilityIcon,
    cardNumber: "2",
    heading: "Code Maintainability",
    description:
      "By minimizing code redundancy and providing a structured approach to test case definition, PypRest contributes to a clean and easily maintainable codebase.",
  },
  {
    icon: AdvantagesComprehensiveValidationIcon,
    cardNumber: "3",
    heading: "Comprehensive Validation",
    description:
      "The framework offers robust validation features, enabling users to verify API responses through customizable checks, assertions, and key validations.",
  },
  {
    icon: AdvantagesFlexiableExecutionOptionIcon,
    cardNumber: "4",
    heading: "Flexible Execution Options",
    description:
      "PypRest provides flexibility in test execution methods, allowing users to choose between the Jewel UI, command line interface, or Python files based on their workflow preferences.",
  },
  {
    icon: AdvantagesEnhancedReportingIcon,
    cardNumber: "5",
    heading: "Enhanced Reporting Capabilities",
    description:
      "Customized reports generated by PypRest offer detailed insights into the success or failure of each test case, facilitating effective issue identification and debugging.",
  },
];
export const aspectAddressedCardsData = [
  {
    heading: "1. Simplified Automation Complexity",
    image: AspectAddressedSimplifiedAutomationComplexityImage,
    description:
      "PypRest addresses the need for a simplified and efficient approach to automating REST API testing, making it accessible even for users with less automation experience.",
  },
  {
    heading: "2. Code Maintenance Challenges",
    image: AspectAddressedCodeMaintenanceChallengesImage,
    description:
      "The framework tackles code maintenance challenges by reducing redundancy and providing a structured method for defining and managing test cases.",
  },
  {
    heading: "3. Validation Accuracy",
    image: AspectAddressedValidationAccuracyImage,
    description:
      "PypRest ensures accurate validation of API responses by offering customizable checks, assertions, and key validations to verify the correctness of the API behavior.",
  },
];
