import MultiDatabaseComparisonIcon from "../../assets/MultiDatabaseComparisonIcon.svg";
import DataComparisonAcrossCSVFileIcon from "../../assets/DataComparisonAcrossCSVFileIcon.svg";
import DataComparisonAcrossDatabaseSourcesIcon from "../../assets/DataComparisonAcrossDatabaseSourcesIcon.svg";
import InnovationInActionIcon from "../../assets/InnovationInActionIcon.svg";
import ProvenExpertiseIcon from "../../assets/ProvenExpertiseIcon.svg";
import UnmatchedEfficiencyIcon from "../../assets/UnmatchedEfficiencyIcon.svg";
import CustomizedComparisonParametersIcon from "../../assets/customizedComparisonParametersIcon.svg";
import DetailedReportsAndInsightsIcon from "../../assets/detailedReportsAndInsightsIcon.svg";
import CollaborationAndSharingIcon from "../../assets/collaborationAndSharingIcon.svg";
import CsvFileIntegrationIcon from "../../assets/csvFileIntegrationIcon.svg";
import IntelligentDataMatchingIcon from "../../assets/intelligentDataMatchingIcon.svg";
import TimeSavingUtilitiesIcon from "../../assets/timeSavingUtilitiesIcon.svg";

export const keyFeatureCardsData = [
  {
    heading: "Multi-Database Comparison",
    icon: MultiDatabaseComparisonIcon,
    description:
      "Say goodbye to the hassles of comparing data from various databases. Our platform supports the seamless integration of different databases, allowing you to effortlessly compare and analyze data across multiple sources.",
  },
  {
    heading: "CSV File Integration",
    icon: CsvFileIntegrationIcon,
    description:
      "In addition to databases, we understand the importance of CSV files in your data ecosystem. Our platform seamlessly integrates with CSV files, ensuring that you can compare data effortlessly, regardless of its source.",
  },
  {
    heading: "Intelligent Data Matching",
    icon: IntelligentDataMatchingIcon,
    description:
      "Our intelligent algorithms go beyond simple comparisons. We employ sophisticated matching techniques to ensure accurate data comparisons, even in scenarios involving complex data structures and diverse formats.",
  },
  {
    heading: "Time-Saving Utilities",
    icon: TimeSavingUtilitiesIcon,
    description:
      "We've curated a set of time-saving utilities to streamline your data comparison process. These utilities are designed to automate repetitive tasks, saving you valuable time and effort.",
  },
  {
    heading: "Customized Comparison Parameters",
    icon: CustomizedComparisonParametersIcon,
    description:
      "Tailor your data comparison to meet your specific needs. Our platform allows you to customize comparison parameters, ensuring flexibility, controllability and accuracy in your analyses.",
  },
  {
    heading: "Detailed Reports and Insights",
    icon: DetailedReportsAndInsightsIcon,
    description:
      "Receive comprehensive reports and insights after each data comparison. Gain a deeper understanding of the nuances in your data, enabling informed decision-making and strategic planning.",
  },
  {
    heading: "Collaboration and Sharing",
    icon: CollaborationAndSharingIcon,
    description:
      "Foster collaboration within your team by easily sharing and collaborating on comparison results. Our platform facilitates seamless sharing of insights and collective data understanding.",
  },
];

export const codeSnippetCardsData = [
  {
    image: DataComparisonAcrossCSVFileIcon,
    description: "Data comparison across csv files",
  },
  {
    image: DataComparisonAcrossDatabaseSourcesIcon,
    description: "Data comparison across database sources",
  },
];

export const aspectAddressCardsData = [
  {
    id: "01",
    image: ProvenExpertiseIcon,
    heading: "1. Proven Expertise",
    description:
      "Our platform is built to address the unique challenges of data comparison be it across different databases or different tables in a database or database against csv file.",
  },
  {
    id: "02",
    image: InnovationInActionIcon,
    heading: "2. Innovation in Action",
    description:
      "We're committed to innovation that directly benefits our users. Our platform is continuously evolving to incorporate the latest advancements in data comparison technology.",
  },
  {
    id: "03",
    image: UnmatchedEfficiencyIcon,
    heading: "3. Unmatched Efficiency",
    description:
      "Experience unmatched efficiency in your data comparison process, thanks to our carefully curated set of utilities and advanced algorithms.",
  },
];

export const dataComparisonAcrossCsv = `<data>
<suite>
<!--Here brigeToken and User are the jewel credentials-->
<project-name>Data Validator</project-name>
<report-name>CSV Comparison</report-name>
<environment>prod</environment>
<mode>optimize</mode>
<enter-point>EnterPointUrl</enter-point>
<threads>4</threads>
<jewel-user>jewelUserName</jewel-user>
<jewel-bridge-token>JewelBridgeToken</jewel-bridge-token>
</suite>
<testcases>
<testcase>
<name>CSV file Comparison with column map</name>
<run_flag>Y</run_flag>
<type>dv</type>
<source_csv>".\source csv file.csv"</source_csv>
<target_csv>".\target csv file.csv"</target_csv>
<keys>ID</keys>
<column_map>{'Emp_ID':'ID'}</column_map>
</testcase>
</testcases>
</data>`;

export const dataComparisonAcrossDatabase = `<data>
<suite>
<project-name>Data Validator</project-name>
<report-name>CSV Comparison</report-name>
<environment>prod</environment>
<mode>optimize</mode>
<enter-point>EnterPointUrl</enter-point>
<threads>4</threads>
<jewel-user>jewelUserName</jewel-user>
<jewel-bridge-token>JewelBridgeToken</jewel-bridge-token>
</suite>
<testcases>
<testcase>
<name>MULTIPLE KEYS AND DIFFERENT SOURCE DB</name>
<run_flag>Y</run_flag>
<source_db>mysql</source_db>
<type>dv</type>
<source_conn>source</source_conn>
<target_conn>mysql.connector.connect(host='xxxxxxxxxxxxxxx',user='xxxxxxx', password='xxxxxx',database='xxxxxxxxxxxxx',port=3306)</target_conn>
<target_db>custom</target_db>
<source_sql>SELECT * from source_schema.source_table</source_sql>
<target_sql>SELECT * from target_schema.target_table</target_sql>
<keys>ID,Location</keys>
<match_case>Department</match_case>
<column_map>{'Emp_ID':'ID'}</column_map>
<db_config_path>./dv.conf</db_config_path>
</testcase>
</testcases>
</data>`;