import React from "react";
import "./HeroSection.css";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import RevealOnScroll from "../../../common/revealOnScroll/RevealOnScroll";

const HeroSection = () => {
  return (
    <div className="hero-section-background  ">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <RevealOnScroll>
          <div className="my-12 lg:my-24">
            <p className="hero-section-heading lg:text-center">
            Fast Pace Effortless Automation
              <span className="hero-section-heading-blue">
                {" "}
                Testing with Reliability
              </span>
            </p>
            <p className="hero-section-description mt-3 lg:text-center">
            API Migration Testing | Data Comparison | API , UI , Functional Automation 
            </p>
            <div className="lg:flex lg:justify-center">
              <Link
                to={
                  process.env.REACT_APP_ENV === "prod"
                    ? "https://gemecosystem.com/"
                    : "https://beta.gemecosystem.com/"
                }
                target="_blank"
              >
                <Button
                  className="base-button primary-button-white hover-right-arrow !mt-8  lg:!mt-10"
                  label="About GemEcosystem"
                />
              </Link>
            </div>
          </div>
        </RevealOnScroll>
      </div>
    </div>
  );
};

export default HeroSection;
