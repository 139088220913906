import React from "react";
import "./TimeEfficientHeroSection.css";

export const TimeEfficientHeroSection = () => {
  return (
    <div className="time-efficient-hero-section-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <p className=" text-center text-3.5xl font-semibold text-white lg:mt-20 lg:text-5xl">
          Time Efficient API Migration Testing
        </p>
        <p className="mt-3 text-center text-lg  text-white lg:mb-20 lg:mt-10 lg:text-2xl">
          Easily automate API tests, compare responses, and ensure smooth
          migrations with GemPyp – simplifying the testing process for
          unparalleled efficiency and accuracy.
        </p>
      </div>
    </div>
  );
};

export default TimeEfficientHeroSection;
