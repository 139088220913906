import React from "react";

const AdvantagesCard = ({ icon, heading, description, cardNumber }) => {
  return (
    <div className="flex flex-col gap-6 lg:flex-row">
      <img
        className="h-28 w-28 lg:h-40 lg:w-40"
        src={icon}
        alt={heading}
        loading="lazy"
      />
      <div className="relative  lg:mt-8">
        <p className="absolute right-0 top-0 -z-10 -translate-y-16 text-[4rem] leading-[6rem] text-primaryColor/10 lg:left-0">
          {cardNumber}
        </p>
        <p className="text-xl font-semibold text-heading lg:text-2xl lg:mb-2">
          {heading}
        </p>
        <p className="text-base text-bodyText lg:text-lg ">{description}</p>
      </div>
    </div>
  );
};

export default AdvantagesCard;
