import React from "react";
import "./WhyChooseUsCard.css";

export const WhyChooseUsCard = ({ heading, image, description }) => {
  return (
    <div className="aspect-address-card-wrapper">
      <div>
        <img
          src={image}
          alt={heading}
          className="h-full w-full  object-cover"
        />
      </div>
      <p className="text-xl font-semibold text-heading">{heading}</p>
      <p className="mt-4 text-bodyText ">{description}</p>
    </div>
  );
};

export default WhyChooseUsCard;
