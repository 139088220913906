import React from "react";


/**
 * WhyChooseUsCard Component
 *
 * @param {string} heading - The heading of the card.
 * @param {string} image - The image source for the card.
 * @param {string} description - The description of the card.
 * @param {string} [headingClasses] - Optional additional classes for the heading.
 * @param {string} [descriptionClasses] - Optional additional classes for the description.
 * @returns {JSX.Element} - The WhyChooseUsCard component.
 */



export const WhyChooseUsCard = ({ heading, image, description,descriptionClasses}) => {
  return (
    <div className="aspect-address-card-wrapper">
      <div>
        <img
          src={image}
          alt={heading}
          className="h-full w-full  object-cover"
        />
      </div>
      <p className="text-xl font-semibold text-heading">{heading}</p>
      <p className={`mt-4 text-bodyText  ${
          descriptionClasses && descriptionClasses
        }`}>{description}</p>
    </div>
  );
};

export default WhyChooseUsCard;
