import StepIntoEfficiencyEffortlessAndQuickAutomationIcon from "../../assets/stepIntoEfficiencyEffortlessAndQuickAutomationIcon.svg";
import StepIntoEfficiencyHassleFreeReportingIcon from "../../assets/stepIntoEfficiencyHassleFreeReportingIcon.svg";
import StepIntoEfficiencyNonProgrammaticAutomationIcon from "../../assets/stepIntoEfficiencyNonProgrammaticAutomationIcon.svg";
import GameChangersCreateSuiteFromJewelUiImage from "../../assets/gameChangersCreateSuiteFromJewelUiImage.png";
import GameChangersEaseOfExecutionImage from "../../assets/gameChangersEaseOfExecutionImage.png";
import GameChangersEaseOfProjectManagementImage from "../../assets/gameChangersEaseOfProjectManagementImage.png";
import GameChangersIntelligentExecutionPrioritizationImage from "../../assets/gameChangersIntelligentExecutionPrioritizationImage.png";
import GameChangersOneForAllImage from "../../assets/gameChangersOneForAllImage.png";
import GameChangersPopularTestFrameworkImage from "../../assets/gameChangersPopularTestFrameworkImage.png";

import footerFacebookIcon from "../../assets/footerFacebookIcon.svg";
import footerInstagramIcon from "../../assets/footerInstagramIcon.svg";
import footerLinkedInIcon from "../../assets/footerLinkedInIcon.svg";
import footerTwitterIcon from "../../assets/footerTwitterIcon.svg";
import whatUserCanDoUiApiFunctionalAutomationIcon from "../../assets/whatUserCanDoUiApiFunctionalAutomationIcon.svg";
import whatUserCanDoDataComparisonIcon from "../../assets/whatUserCanDoDataComparisonIcon.svg";
import whatUserCanDoTimeEfficientApiMigrationTestingIcon from "../../assets/whatUserCanDoTimeEfficientApiMigrationTestingIcon.svg";

export const stepIntoEfficiencyCardData = [
  {
    id: "01-automation-ready",
    icon: StepIntoEfficiencyEffortlessAndQuickAutomationIcon,
    heading: "Effortless and Quick Automation",
    briefDescription: "At your Fingertips",
    description:
      'GemPyp follows the motto "Just Configure" that aims to just install GemPyp and fill the XML-based configuration file or JEWEL UI form and automation is ready. The XML configuration file contains the business logic be it for API automation, Database comparisons and other automations',
  },
  {
    id: "02-automation",
    icon: StepIntoEfficiencyHassleFreeReportingIcon,
    heading: "Hassle-free Reporting",
    briefDescription: "Seamless Integration and Intelligent Reporting",
    description:
      "Integration of GemPyp with JEWEL, our premium test management product, enables intelligent handling of re-runs, analytical reporting, and AI-driven test suite creation, enhancing test management capabilities and decision-making.",
  },
  {
    id: "03-no-utility",
    icon: StepIntoEfficiencyNonProgrammaticAutomationIcon,
    heading: "Non-Programmatic Automation",
    briefDescription: "No Coding, Just Configuring",
    description:
      "GemPyp aims to simplify automation processes, making it more accessible to individuals who may not have programming expertise. To achieve this, you do not need traditional programming. Automation can be done by a configuration file rather than writing script.",
  },
];

export const gameChangerData = [
  {
    id: "01",
    img: GameChangersOneForAllImage,
    heading: "One for All",
    description:
      "With GemPyp, API testing, UI testing, Database and file comparisons can be effortlessly performed in a single execution, eliminating the need for script writing, thus leveraging multiple features seamlessly.",
  },
  {
    id: "02-ease",
    img: GameChangersEaseOfExecutionImage,
    heading: "Ease of Execution",
    description:
      "Single Click execution is supported through JEWEL UI along with command-line execution on Windows, Linux, Mac and offers flexibility and ease of use by running through Python scripts integrated with popular IDEs such as VS Code and notebook.",
  },
  {
    id: "03",
    img: GameChangersCreateSuiteFromJewelUiImage,
    heading: "Create Suite from JEWEL UI",
    description:
      "GemPyp integrated with JEWEL facilitates test case creation, generates analytical reports, and provides distinct scores for each model to evaluate product quality, ensuring a seamless user experience.",
  },
  {
    id: "04-update",
    img: GameChangersIntelligentExecutionPrioritizationImage,
    heading: "Intelligent Execution Prioritization",
    description:
      "GemPyp intelligently allows users to define the condition to execute a testcase over the result of other testcase while executing parallelly.",
  },
  {
    id: "05",
    img: GameChangersEaseOfProjectManagementImage,
    heading: "Ease of Project Management",
    description:
      "GemPyp simplifies the entire project management lifecycle, effortlessly creating, defining, and closing bugs/tasks within a test suite.",
  },
  {
    id: "06",
    img: GameChangersPopularTestFrameworkImage,
    heading: "Popular Test Framework and Report Integration",
    description:
      "It's plugin seamlessly integrates Pytest and Unittest frameworks, eliminating the need for managing multiple reporting and configuration setups.",
  },
];

export const footerLinksData = [
  {
    id: "01-getting-started",
    heading: "Getting Started",
    children: [
      {
        id: "01-why",
        heading: "Why Gemecosystem?",
        betaLink: "https://beta.gemecosystem.com/#/how-it-works",
        link: "https://gemecosystem.com/#/how-it-works",
        openLinkInNewTab: true,
      },
      {
        id: "02-release-notes",
        heading: "Release Notes",
        link: "https://blustone.gemecosystem.com/2024/02/07/gempyp-release-notes/",
        openLinkInNewTab: true,
      },
      {
        id: "03-plugins",
        heading: "Plugins",
        link: "https://blustone.gemecosystem.com/2024/02/02/gempyp-plugin/",
        openLinkInNewTab: true,
      },
    ],
  },
  {
    id: "02-developers",
    heading: "Developers",
    children: [
      {
        id: "01-getting-started",
        heading: "Getting Started",
        link: "https://blustone.gemecosystem.com/2024/02/09/getting-started-with-gempyp/",
        openLinkInNewTab: true,
      },
      {
        id: "02-pypRest",
        heading: "PypRest",
        link: "/pyp-rest",
      },
      {
        id: "03-data-validator",
        heading: "Data Validator",
        link: "/data-validator",
      },
    ],
  },
  {
    id: "03-our",
    heading: "Our Offerings",
    children: [
      {
        id: "01-working-at",
        heading: "Jewel",
        openLinkInNewTab: true,
        betaLink: "https://jewel-beta.gemecosystem.com/",
        link: "https://jewel.gemecosystem.com/",
      },
      {
        id: "02-gemjar",
        heading: "GemJar",
        openLinkInNewTab: true,
        betaLink: "https://gemjar-beta.gemecosystem.com/",
        link: "https://gemjar.gemecosystem.com/",
      },
      {
        id: "03-gem-pyp",
        heading: "GemPyp",
        betaLink: "https://gempyp-beta.gemecosystem.com/",
        link: "https://gempyp.gemecosystem.com/",
      },
      {
        id: "04-gemprf",
        heading: "GemPrf",
        openLinkInNewTab: true,
        betaLink: "https://beta.gemecosystem.com/#/gem-prf",
        link: "https://gemecosystem.com/#/gem-prf",
      },
    ],
  },
  {
    id: "04-company",
    heading: "Company",
    children: [
      {
        id: "01-about-us",
        heading: "About Us",
        betaLink: "https://beta.gemecosystem.com/#/about-us",
        link: "https://gemecosystem.com/#/about-us",
        openLinkInNewTab: true,
      },
      {
        id: "02-blogs",
        heading: "Blogs",
        link: "https://blustone.gemecosystem.com/",
        openLinkInNewTab: true,
      },
      {
        id: "03-contact-us",
        heading: "Contact US",
        link: "contact-us",
        openLinkInNewTab: true,
        betaLink:
          "https://beta.gemecosystem.com/#/gem-consultancy#contact-for-resources",
        link: "https://gemecosystem.com/#/gem-consultancy#contact-for-resources",
      },
      {
        id: "02-working-at",
        heading: "Working at Gemini",
        openLinkInNewTab: true,
        link: "https://www.geminisolutions.com/life-at-gemini",
      },
    ],
  },
];

export const footerIcons = [
  {
    id: "facebook",
    icon: footerFacebookIcon,
    path: "M11.7227 9L12.167 6.10437H9.38859V4.22531C9.38859 3.43313 9.77672 2.66094 11.0211 2.66094H12.2842V0.195625C12.2842 0.195625 11.138 0 10.042 0C7.75391 0 6.25828 1.38688 6.25828 3.8975V6.10437H3.71484V9H6.25828V16H9.38859V9H11.7227Z",
    link: "https://www.facebook.com/gemecosys",
  },
  {
    id: "Twitter",
    icon: footerTwitterIcon,
    path: "M14.3553 5.15848C14.3655 5.30061 14.3655 5.44277 14.3655 5.58489C14.3655 9.91989 11.066 14.9148 5.03553 14.9148C3.17766 14.9148 1.45178 14.3767 0 13.4428C0.263969 13.4732 0.51775 13.4834 0.791875 13.4834C2.32484 13.4834 3.73603 12.9656 4.86294 12.0824C3.42131 12.0519 2.21319 11.1077 1.79694 9.80823C2 9.83867 2.20303 9.85898 2.41625 9.85898C2.71066 9.85898 3.00509 9.81836 3.27919 9.74733C1.77666 9.44273 0.649719 8.12295 0.649719 6.52905V6.48845C1.08625 6.73211 1.59391 6.88439 2.13194 6.90467C1.24869 6.31583 0.670031 5.31077 0.670031 4.1737C0.670031 3.56458 0.832437 3.0062 1.11672 2.51889C2.73094 4.50873 5.15734 5.8082 7.87812 5.95036C7.82737 5.7067 7.79691 5.45292 7.79691 5.19911C7.79691 3.39198 9.25884 1.91992 11.0761 1.91992C12.0202 1.91992 12.873 2.31586 13.472 2.95545C14.2131 2.81333 14.9238 2.5392 15.5532 2.16358C15.3096 2.92502 14.7918 3.56461 14.1116 3.97067C14.7715 3.89964 15.4111 3.71686 15.9999 3.46308C15.5533 4.1128 14.9949 4.69145 14.3553 5.15848V5.15848Z",
    link: "https://twitter.com/gemecosystem",
  },
  {
    id: "Instagram",
    icon: footerInstagramIcon,
    path: "M8.00352 4.40635C6.01602 4.40635 4.41289 6.00947 4.41289 7.99697C4.41289 9.98447 6.01602 11.5876 8.00352 11.5876C9.99102 11.5876 11.5941 9.98447 11.5941 7.99697C11.5941 6.00947 9.99102 4.40635 8.00352 4.40635ZM8.00352 10.3313C6.71914 10.3313 5.66914 9.28447 5.66914 7.99697C5.66914 6.70947 6.71602 5.6626 8.00352 5.6626C9.29102 5.6626 10.3379 6.70947 10.3379 7.99697C10.3379 9.28447 9.28789 10.3313 8.00352 10.3313V10.3313ZM12.5785 4.25947C12.5785 4.7251 12.2035 5.09697 11.741 5.09697C11.2754 5.09697 10.9035 4.72197 10.9035 4.25947C10.9035 3.79697 11.2785 3.42197 11.741 3.42197C12.2035 3.42197 12.5785 3.79697 12.5785 4.25947ZM14.9566 5.10947C14.9035 3.9876 14.6473 2.99385 13.8254 2.1751C13.0066 1.35635 12.0129 1.1001 10.891 1.04385C9.73477 0.978223 6.26914 0.978223 5.11289 1.04385C3.99414 1.09697 3.00039 1.35322 2.17852 2.17197C1.35664 2.99072 1.10352 3.98447 1.04727 5.10635C0.981641 6.2626 0.981641 9.72822 1.04727 10.8845C1.10039 12.0063 1.35664 13.0001 2.17852 13.8188C3.00039 14.6376 3.99102 14.8938 5.11289 14.9501C6.26914 15.0157 9.73477 15.0157 10.891 14.9501C12.0129 14.897 13.0066 14.6407 13.8254 13.8188C14.6441 13.0001 14.9004 12.0063 14.9566 10.8845C15.0223 9.72822 15.0223 6.26572 14.9566 5.10947V5.10947ZM13.4629 12.1251C13.2191 12.7376 12.7473 13.2095 12.1316 13.4563C11.2098 13.822 9.02227 13.7376 8.00352 13.7376C6.98477 13.7376 4.79414 13.8188 3.87539 13.4563C3.26289 13.2126 2.79102 12.7407 2.54414 12.1251C2.17852 11.2032 2.26289 9.01572 2.26289 7.99697C2.26289 6.97822 2.18164 4.7876 2.54414 3.86885C2.78789 3.25635 3.25977 2.78447 3.87539 2.5376C4.79727 2.17197 6.98477 2.25635 8.00352 2.25635C9.02227 2.25635 11.2129 2.1751 12.1316 2.5376C12.7441 2.78135 13.216 3.25322 13.4629 3.86885C13.8285 4.79072 13.7441 6.97822 13.7441 7.99697C13.7441 9.01572 13.8285 11.2063 13.4629 12.1251Z",
    link: "https://instagram.com/gemecosystem",
  },
  {
    id: "LinkedIn",
    icon: footerLinkedInIcon,
    path: "M4.13375 14.0002H1.23125V4.6533H4.13375V14.0002ZM2.68094 3.3783C1.75281 3.3783 1 2.60955 1 1.68143C1 1.23561 1.1771 0.80806 1.49234 0.492823C1.80757 0.177587 2.23513 0.000488281 2.68094 0.000488281C3.12675 0.000488281 3.5543 0.177587 3.86954 0.492823C4.18478 0.80806 4.36188 1.23561 4.36188 1.68143C4.36188 2.60955 3.60875 3.3783 2.68094 3.3783ZM14.9969 14.0002H12.1006V9.45017C12.1006 8.3658 12.0787 6.97518 10.5916 6.97518C9.0825 6.97518 8.85125 8.1533 8.85125 9.37205V14.0002H5.95188V4.6533H8.73562V5.9283H8.77625C9.16375 5.19393 10.1103 4.41893 11.5225 4.41893C14.46 4.41893 15 6.3533 15 8.8658V14.0002H14.9969Z",
    link: "https://www.linkedin.com/company/gemecosystem",
  },
];

export const mobileNavBarAccordionData = [
  {
    id: "01-getting",
    heading: "Getting Started",
    children: [],
  },
  {
    id: "02-resources",
    heading: "Resources",
    children: [
      {
        id: "01-support",
        heading: "Support",
        link: "",
      },
    ],
  },
  {
    id: "03-contact",
    heading: "Contact Us",
    children: [],
  },
  {
    id: "04-open-api",
    heading: "Open APIs",
    children: [],
  },
];
export const headerLinksData = [
  {
    id: "01-getting",
    name: "Getting Started",
    link: "https://blustone.gemecosystem.com/2024/02/09/getting-started-with-gempyp/",
    openInNewTab: true,
  },
  {
    id: "02-pyp-rest",
    name: "PypRest",
    link: "/pyp-rest",
    hash: "#/pyp-rest",
  },
  {
    id: "03-data-validator",
    name: "Data Validator",
    link: "/data-validator",
    hash: "#/data-validator",
  },
  {
    id: "04-release-notes",
    name: "Release Notes",
    link: "https://blustone.gemecosystem.com/2024/02/07/gempyp-release-notes/",
    openInNewTab: true,
  },
  {
    id: "05-plugins",
    name: "Plugins",
    link: "https://blustone.gemecosystem.com/2024/02/02/gempyp-plugin/",
    openInNewTab: true,
  },
];
export const realNumbersData = [
  { id: "01", count: 17, heading: "No. of Downloads" },
  { id: "02", count: 1000, heading: "Testcase executed" },
  { id: "03", count: 13, heading: "Failures Caught" },
];

export const whatUsersCanDoData = [
  {
    id: "01",
    image: whatUserCanDoTimeEfficientApiMigrationTestingIcon,
    heading: "Time Efficient API Migration Testing",
    description:
      "GemPyp extends its capabilities to automate REST APIs without writing any code. One of its feature to compare legacy API response, status codes and other comparison with the latest API version makes it highly time efficient for API Migration testing.",
    buttonLabel: "Learn More",
    link: "/time-efficient",
  },
  {
    id: "02",
    image: whatUserCanDoUiApiFunctionalAutomationIcon,
    heading: "UI, API, Functional Automation",
    description:
      "GemPyp empowers users to conduct a wide range of Automation through an XML-based configuration file for API and functional automation. Additionally, it follows BDD approach for UI Automation. In all the automations, GemPyp allows users to leverage all the features and customize it as per their business requirement.",
    buttonLabel: "Learn More",
    link: "/ui-automation",
  },
  {
    id: "03",
    image: whatUserCanDoDataComparisonIcon,
    heading: "Data Comparison",
    description:
      "Data comparison across diverse databases and Comma Separated Value(.csv) files. It not only compares data but also gives innumerable utilities curated after thorough research and survey with the experts to enhance user experience and their reduce time and effort.",
    buttonLabel: "Learn More",
    link: "/data-comparison",
  },
];
