import React from "react";
import ScrollImageTransitionAnimation from "../../../common/scrollImageTransitionAnimation/ScrollImageTransitionAnimation";

import { uiAutomationkeyFeaturesCardData } from "../../../helper/cardsData/uiAutomationPageMockData";

const UiAutomationKeyFeatures = () => {
  return (
    <ScrollImageTransitionAnimation
      heading="Key Features"
      cardsData={uiAutomationkeyFeaturesCardData}
    />
  );
};

export default UiAutomationKeyFeatures;
