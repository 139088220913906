import React from "react";
import TimeEfficientKeyFeatures from "../../components/timeEfficientPage/timeEfficientKeyFeatures/TimeEfficientKeyFeatures";
import TimeEfficientHeroSection from "../../components/timeEfficientPage/timeEfficientHeroSection/TimeEfficientHeroSection";
import WhyChooseYouUs from "../../components/timeEfficientPage/whyChooseYouUs/WhyChooseYouUs";

const TimeEfficientPage = () => {
  return (
    <>
      <TimeEfficientHeroSection />
      <TimeEfficientKeyFeatures />
      <WhyChooseYouUs />
    </>
  );
};

export default TimeEfficientPage;
