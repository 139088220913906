import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import useCasesDartIcon from "../../../assets/useCasesDartIcon.svg";

const UseCasesCard = ({ heading, description }) => {
  return (
    <div>
      <p className="text-xl font-semibold text-heading lg:text-2xl">
        {heading}
      </p>
      <p className="mt-2 text-base text-bodyText">{description}</p>
    </div>
  );
};

const UseCases = () => {
  return (
    <div className="bg-[#023B59]/10">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Use Cases" />
        <div className="mt-12 grid grid-cols-1 lg:grid-cols-3  ">
          <div className=" order-2  mt-8 flex flex-col gap-8 lg:order-1  lg:mt-0 lg:justify-between">
            <UseCasesCard
              heading="Data Validation in ETL Processes"
              description="Integrates well with ETL processes for validating and ensuring the correctness of transformed data"
            />
            <UseCasesCard
              heading="Data Migration"
              description="Useful during the migration of data between databases to ensure accuracy"
            />
          </div>
          <img
            className="order-1  justify-self-center lg:order-2 "
            src={useCasesDartIcon}
            alt="dart board"
            loading="lazy"
          />
          <div className="  order-3  mt-8 flex flex-col  gap-8  lg:mt-0 lg:justify-between">
            <UseCasesCard
              heading="Quality Assurance"
              description="Ideal for QA teams to validate data integrity between different datasets."
            />
            <UseCasesCard
              heading="Cross-Database Analysis"
              description="Enables analysts to perform cross-database analysis efficiently"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
