import React, { useEffect, useRef, useState } from "react";
import copyIcon from "../../assets/copyIcon.svg";
import codeCopiedIcon from "../../assets/codeCopiedIcon.svg";
import { Button } from "primereact/button";
import highlightJs from "highlight.js/lib/core";
import gherkin from "highlight.js/lib/languages/gherkin";
import python from "highlight.js/lib/languages/python";
import plaintext from "highlight.js/lib/languages/plaintext";
import xml from "highlight.js/lib/languages/xml";
import "highlight.js/styles/github-dark.css";

highlightJs.registerLanguage("gherkin", gherkin);
highlightJs.registerLanguage("python", python);
highlightJs.registerLanguage("plaintext", plaintext);
highlightJs.registerLanguage("xml", xml);

/**
 * CodeSnippet component for displaying highlighted code.
 *
 * @component
 * @param {Object} props - React component props.
 * @param {string} props.children - The code to be displayed.
 * @param {string} props.language - The language of the code (e.g., "javascript","python").
 * @returns {JSX.Element} - The rendered component.
 */
const CodeSnippet = ({ children, language, snippetTitle }) => {
  const [copyIconSource, setCopyIconSource] = useState(copyIcon);
  const codeRef = useRef(null);

  useEffect(() => {
    // Highlight the code block once the component mounts.
    highlightJs.highlightBlock(codeRef.current);
  }, []);

  const highlightedCode = highlightJs.highlight(children, {
    language: language,
  }).code;

  function copyCodeFunction() {
    navigator.clipboard.writeText(children);
    setCopyIconSource(codeCopiedIcon);
  }

  return (
    <div className="flex flex-col overflow-auto">
      <pre className="group relative flex-1">
        <code
          ref={codeRef}
          style={{
            borderRadius: "1rem",
            height: "100%",
            minHeight: "5rem",
            maxHeight: "75vh",
          }}
        >
          {highlightedCode}
        </code>

        <Button
          className="absolute right-0 top-0 m-4 mr-8 backdrop-blur-sm lg:opacity-0 lg:transition-all lg:hover:!opacity-100 lg:group-hover:opacity-50"
          title="copy"
          onMouseLeave={() => {
            setCopyIconSource(copyIcon);
          }}
          onClick={copyCodeFunction}
        >
          <img
            className="h-12 w-12 shadow-sm  "
            src={copyIconSource}
            alt="copy code"
            loading="lazy"
          />
        </Button>
      </pre>
      <p className="mt-6 text-center text-heading text-xl font-semibold lg:text-2xl  ">
        {snippetTitle}
      </p>
    </div>
  );
};

export default CodeSnippet;
