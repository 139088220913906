import React from "react";
import "./PypRestHeroSection.css";

const PypRestHeroSection = () => {
  return (
    <div className="heroSection-background-wrapper">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <p className=" text-center  text-3.5xl font-semibold text-white lg:mt-20 lg:text-5xl">
          PypRest
        </p>

        <p className="  mt-3 text-center text-lg font-normal text-white lg:mb-20  lg:mt-10 lg:text-2xl">
          PypRest is an API automation testing framework written in Python that
          simplifies and enhances the process of testing REST APIs. The
          framework utilizes a configuration XML file to define test cases,
          providing a structured and concise approach to automation logic.
        </p>
      </div>
    </div>
  );
};

export default PypRestHeroSection;
