import React from "react";
import { UiAutomationWhyChooseUsCardsData } from "../../../helper/cardsData/uiAutomationPageMockData";
import UiAutomationWhyChooseUsCard from "./uiAutomationWhyChooseUsCard/UiAutomationWhyChooseUsCard";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

export const UiAutomationWhyChooseUs = () => {
  return (
    <div className="why-choose-us-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Why Choose Us?" />
        <div className=" mt-8  grid  gap-8 md:grid-cols-2 lg:mt-12 lg:grid-cols-3 ">
          {UiAutomationWhyChooseUsCardsData.map((cardData) => {
            return (
              <UiAutomationWhyChooseUsCard
                key={cardData.heading}
                image={cardData.image}
                heading={cardData.heading}
                description={cardData.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UiAutomationWhyChooseUs;
