import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { advantagesCardData } from "../../../helper/cardsData/dataValidatorMockData";
import AdvantagesCard from "../../../common/advantagesCard/AdvantagesCard";

const Advantages = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="Advantages" />
      <div className="mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-2">
        {advantagesCardData.map((item) => {
          return (
            <AdvantagesCard
              icon={item.icon}
              heading={item.heading}
              description={item.description}
              cardNumber={item.cardNumber}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Advantages;
