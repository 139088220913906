import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import { Link } from "react-router-dom";
import { headerLinksData } from "../../../helper/cardsData/homepageMockData";
import "./mobileNavDrawer.css";
import jewelIcon from "../../../assets/jewelIcon.svg";

const MobileNavDrawer = ({ open, setOpen, windowHash }) => {
  return (
    <Sidebar position="right" visible={open} onHide={() => setOpen(false)}>
      <div className="mt-4">
        {headerLinksData.map((item) => {
          return (
            <div key={item.id} className="mt-4 w-fit">
              <Link
                className={`text-lg font-semibold  ${
                  item.hash === windowHash
                    ? "text-primaryColor"
                    : " text-heading"
                }`}
                to={item.link}
                target={item.openInNewTab && "_blank"}
              >
                <p className="py-2">{item.name}</p>
              </Link>
            </div>
          );
        })}
        <div className="mt-8 flex gap-x-6">
          <Link
            to={
              process.env.REACT_APP_ENV === "prod"
                ? "https://jewel.gemecosystem.com/#/signup"
                : "https://jewel-beta.gemecosystem.com/#/signup"
            }
            className="flex-1"
          >
            <Button
              label="Sign up"
              title="Sign up with Jewel"
              className="base-button secondary-button h-full w-full "
              icon={
                <img
                  src={jewelIcon}
                  className="mr-2 h-6 w-6 "
                  alt="sign up with jewel"
                />
              }
              iconPos="left"
            />
          </Link>
          <Link
            to={
              process.env.REACT_APP_ENV === "prod"
                ? "https://jewel.gemecosystem.com/#/login"
                : "https://jewel-beta.gemecosystem.com/#/login"
            }
            className="flex-1"
          >
            <Button
              label="Login"
              title="Login with Jewel"
              className="base-button primary-button h-full w-full"
              icon={
                <img
                  src={jewelIcon}
                  className="mr-2 h-6 w-6 "
                  alt="login with jewel"
                />
              }
              iconPos="left"
            />
          </Link>
        </div>
      </div>
    </Sidebar>
  );
};

export default MobileNavDrawer;
