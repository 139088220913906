import React from "react";
import ScrollImageTransitionAnimation from "../../../common/scrollImageTransitionAnimation/ScrollImageTransitionAnimation";
import { featuresCardData } from "../../../helper/cardsData/dataValidatorMockData";

const DataValidatorFeatures = () => {
  return (
    <ScrollImageTransitionAnimation
      heading="Features"
      cardsData={featuresCardData}
    />
  );
};

export default DataValidatorFeatures;
