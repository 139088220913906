import CodeSnippet from "../../../common/codeSnippet/CodeSnippet";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import {
  stepDefinitionFileCodeSnippet,
  featureFileCodeSnippet,
  behaveFileCodeSnippet,
  environmentFileCodeSnippet,
} from "../../../helper/cardsData/uiAutomationPageMockData";
import "./CodeSnippetForVariousScenarios.css";
import React from "react";

const CodeSnippetForVariousScenarios = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="UI Snippets for Various Scenarios" />
      <div className="mt-8 grid gap-8 lg:mt-12 lg:grid-cols-2 lg:gap-12">
        <CodeSnippet language="gherkin" snippetTitle="Feature File">
          {`${featureFileCodeSnippet}`}
        </CodeSnippet>
        <CodeSnippet language="python" snippetTitle="Step Definition File">
          {`${stepDefinitionFileCodeSnippet}`}
        </CodeSnippet>
        <CodeSnippet language="python" snippetTitle="Environment File">
          {`${environmentFileCodeSnippet}`}
        </CodeSnippet>
        <CodeSnippet language="plaintext" snippetTitle="Behave.ini File">
          {`${behaveFileCodeSnippet}`}
        </CodeSnippet>
      </div>
    </div>
  );
};

export default CodeSnippetForVariousScenarios;
