import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { Carousel } from "primereact/carousel";
import KeyFeatureCard from "../../../common/keyFeatureCard/KeyFeatureCard";
import {
  timeEfficientCodeSnippetText,
  timeEfficientKeyFeaturesCardData,
} from "../../../helper/cardsData/timeEfficientMockData";
import CodeSnippet from "../../../common/codeSnippet/CodeSnippet";

const TimeEfficientKeyFeatures = () => {
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const CarouselKeyFeatureCard = ({ icon, heading, description }) => {
    return (
      <div className="md:p-2">
        <KeyFeatureCard
          icon={icon}
          heading={heading}
          description={description}
          descriptionClasses="h-48 md:h-36 lg:h-48 "
        />
      </div>
    );
  };
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="Key Features" />
      <div className="mt-8 lg:mt-12 lg:mb-12 mb-8">
        <Carousel
          value={timeEfficientKeyFeaturesCardData}
          itemTemplate={CarouselKeyFeatureCard}
          numVisible={3}
          responsiveOptions={responsiveOptions}
        />
      </div>
      <ComponentHeading heading="Code Snippet"/>
      <div className="mt-8 lg:mx-24 lg:mt-12">
        <CodeSnippet
          language="xml"
          snippetTitle="Code snippet of legacy comparison"
        >{`${timeEfficientCodeSnippetText}`}</CodeSnippet>
      </div>
    </div>
  );
};

export default TimeEfficientKeyFeatures;
