import { Button } from "primereact/button";
import React from "react";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import RevealOnScroll from "../../../common/revealOnScroll/RevealOnScroll";
import DataValidatorHeroSectionImage from "../../../assets/dataValidatorHeroSectionImage.png";

import "./DataValidatorHeroSection.css";

const DataValidatorHeroSection = () => {
  return (
    <div className=" bg-[#023B59]/10 ">
      <div className="mx-auto max-w-screen-2xl">
        <RevealOnScroll>
          <div className="component-padding lg:grid lg:grid-cols-2  lg:items-center lg:gap-x-14 ">
            <div>
              <p className="H5 text-heading lg:!text-[2.5rem] lg:!leading-[3.125rem] ">
                Data Validator
              </p>
              <p className="mt-3 text-lg text-bodyText lg:text-2xl ">
                A powerful Python-based tool with a focus on comparing data
                between different datasets.
              </p>
            </div>
            {/* <div className=" video-player-wrapper mt-10 aspect-video    lg:mt-0">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LWJutrj2rgI"
                height="100%"
                width="100%"
                controls
                light
              />
            </div> */}
             <img
            className="mt-8 lg:mt-0"
            src={DataValidatorHeroSectionImage}
            alt="tools and code"
          />
          </div>
        </RevealOnScroll>
      </div>
    </div>
  );
};

export default DataValidatorHeroSection;
