import React from "react";
import { aspectAddressedCardsData } from "../../../helper/cardsData/pypRestPageMockData";
import AspectAddressedCard from "./aspectAddressedCard/AspectAddressedCard";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import "./AspectAddressed.css";

const AspectAddressed = () => {
  return (
    <div className="aspect-addressed-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Aspects Addressed by PypRest" />
        <div className=" mt-8 grid gap-8 md:grid-cols-2 lg:mt-12 lg:grid-cols-3 lg:gap-8 ">
          {aspectAddressedCardsData.map((cardData) => {
            return (
              <AspectAddressedCard
                key={cardData.id}
                heading={cardData.heading}
                image={cardData.image}
                description={cardData.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AspectAddressed;
