import uiAutomationBDDAndTDDCapabilitiesIcon from "../../assets/uiAutomationBDDAndTDDCapabilitiesIcon.svg";
import uiAutomationEfficencyRefinedInFunctionalTestingIcon from "../../assets/uiAutomationEfficencyRefinedInFunctionalTestingIcon.svg";
import uiAutomationEffortlessApiTestingWithMinimalCodeIcon from "../../assets/uiAutomationEffortlessApiTestingWithMinimalCodeIcon.svg";
import uiAutomationHarmonizedDataComparisonExperienceIcon from "../../assets/uiAutomationHarmonizedDataComparisonExperienceIcon.svg";
import InnovativeSolutionsForEvolvingUiChallengesIcon from "../../assets/innovativeSolutionsForEvolvingUiChallengesIcon.svg";
import ActionableReportingAndAnalyticsIcon from "../../assets/actionableReportingAndAnalyticsIcon.svg";
import SupportForParallelExecutionIcon from "../../assets/supportForParallelExecutionIcon.svg";

import uiAutomationAdditionalOfferingsIcon1 from "../../assets/uiAutomationAdditionalOfferingsIcon1.svg";
import uiAutomationAdditionalOfferingsIcon2 from "../../assets/uiAutomationAdditionalOfferingsIcon2.svg";
import uiAutomationAdditionalOfferingsIcon3 from "../../assets/uiAutomationAdditionalOfferingsIcon3.svg";
import uiAutomationAdditionalOfferingsIcon4 from "../../assets/uiAutomationAdditionalOfferingsIcon4.svg";

export const uiAutomationkeyFeaturesCardData = [
  {
    image: uiAutomationBDDAndTDDCapabilitiesIcon,
    heading: "BDD and TDD Capabilities",
    bulletPoints: [
      "Switch between flexible TDD and BDD approaches based on project requirements.",
      "Behave and Pytest Integration.",
    ],
  },
  {
    image: uiAutomationEffortlessApiTestingWithMinimalCodeIcon,
    heading: "Effortless API Testing with Minimal Code",
    bulletPoints: [
      "Empowering Minimal Code API Automation using XML/Form based configuration file.",
      "Highly time efficient where the first automation can be ready in 5 mins.",
    ],
  },
  {
    image: uiAutomationHarmonizedDataComparisonExperienceIcon,
    heading: "Harmonized Data Comparison Experience",
    bulletPoints: [
      "Experience a new level of precision in multiple scenarios with our integrated data comparison feature.",
      "Compare datasets effortlessly across databases and CSV files using XML/Form based configuration file.",
    ],
  },
  {
    image: uiAutomationEfficencyRefinedInFunctionalTestingIcon,
    heading: "Efficiency Redefined in Functional Testing",
    bulletPoints: [
      "A comprehensive suite for functional testing, with intelligent inter-testcase dependency handling.",
      "Receive actionable insights of your application's functionality through Jewel Reporting.",
    ],
  },
];

export const UiAutomationWhyChooseUsCardsData = [
  {
    id: "01",
    image: InnovativeSolutionsForEvolvingUiChallengesIcon,
    heading: "1. Innovative Solutions for Evolving UI Challenges",
    description:
      "Witness an ongoing commitment to innovation as GemPyp adapts to the ever-changing landscape of UI testing technology. Stay at the forefront of advancements with features that enhance UI testing experience.",
  },
  {
    id: "02",
    image: ActionableReportingAndAnalyticsIcon,
    heading: "2. Actionable Reporting and Analytics",
    description:
      "Gain valuable insights into your application's UI performance with detailed and actionable reports. Our analytics empower you to make informed decisions and prioritize enhancements effectively.",
  },
  {
    id: "03",
    image: SupportForParallelExecutionIcon,
    heading: "3. Support for Parallel Execution",
    description:
      "Optimize test execution time by leveraging support for parallel execution along with intelligent inter testcase dependency handling. Therefore, reducing execution time and accelerating the real-time reporting.",
  },
];

export const featureFileCodeSnippet = `Feature: MIS_Automation - Dashboard Profile

Background: Login to MIS
    Given Navigate to website
    Given Login to MIS with Username "*********" and password "***********"
    When Click on Signin button
    Then User should be navigated to MIS homepage

Scenario Outline: Dashboard Profile: User enter invalid phone number
    When Click on edit details button
    Then Enter mobile number
    Then User clicks on Update button
    Then Verify popup with message "<AlertType>" and "<Message>"
    Examples:
        | AlertType | Message                            |
        | Sucsess   | Your profile data has been updated successfully.   |


Scenario Outline: Dashboard Profile: User enter invalid emergency phone number       
    When Click on edit details button
    Then Enter emergency number "<phoneNumber>"
    Then User clicks on Update button
    Then Verify popup with message "<AlertType>" and "<Message>"
    Examples:
        | AlertType | Message                            | phoneNumber |
        | Warning   | Please put 10 digit mobile number. | 123456789   |
        `;

export const stepDefinitionFileCodeSnippet = `from behave import given ,when ,then
        from gempyp.mis_automation.utils.dashboardProfileUtils import dashboardProfile            
        import time
         
         
        @given(u'Navigate to website')
        def navigateToWebsite(context):
                dashboardProfile.navigate()
         
        @given(u'Login to MIS with Username "{username}" and password "{password}"')
        def login_to_mis_with_username_and_password(context, username, password):
                dashboardProfile.sendKeys(username)
                dashboardProfile.sendKeys(password)
         
        @when(u"Click on Signin button")
        def click_on_signin_button(context):
                dashboardProfile.clickButton("signIn")
         
        @then(u"User should be navigated to MIS homepage")
        def navigateMisHomepage(context):
                dashboardProfile.verifyElement("geminiLogo")
         
        @when("Click on edit details button")
        def clickOnEditDetailsButton(context):
         
                dashboardProfile.clickButton("clickOnChangeDetails")`;

export const environmentFileCodeSnippet = `from gempyp.plugin.custom_hooks import *`;
export const behaveFileCodeSnippet = `[behave]
project-name=*********
environment=beta
jewel-user=***********
jewel-bridge-token=*****************************************************
enter-point=************************************`;

export const oneForAllCodeSnippet = `<testcase>
    <name>Register using correct credentials</name>
    <type>pyprest</type>
    <run_flag>Y</run_flag>
    <method>post</method>
    <api>https://****************/******/register</api>
    <body>{
        "fullName": "Harssh Mahajan",
        "emailId":"*********@gmail.com",
        "password": "**************"
    }</body>
    <expected-status-code>200,201</expected-status-code>
</testcase>
<testcase>
    <name>Validate Project</name>
    <run_flag>Y</run_flag>
    <path>.\\testcase.py</path>
    <type>gempyp</type>
    <method>validate_project</method>
</testcase>
<testcase>
    <name>Comparing CSV Files</name>
    <run_flag>Y</run_flag>
    <type>dv</type>
    <source_csv>.\***********.csv</source_csv>
    <target_csv>.\***********.csv</target_csv>
    <keys>ID</keys>
    <column_map>{'Emp_ID':'ID'}</column_map>
    <cut_out>50</cut_out>
</testcase>`;

export const additionalOfferingsCardsData = [
  {
    icon: uiAutomationAdditionalOfferingsIcon1,
    heading: "1. One XML for all the types of automation",
  },
  {
    icon: uiAutomationAdditionalOfferingsIcon2,
    heading: "2. Runtime variable creation and value assignment",
  },
  {
    icon: uiAutomationAdditionalOfferingsIcon3,
    heading: "3. Custom and Real-Time Reporting",
  },
  {
    icon: uiAutomationAdditionalOfferingsIcon4,
    heading: "4. Multiple XML execution in a single invocation",
  },
];
