import React from "react";
import { aspectAddressCardsData } from "../../../helper/cardsData/dataComparisonMockData";
import WhyChooseUsCard from "./whyChooseUsCard/WhyChooseUsCard";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import "./WhyChooseUs.css";

export const WhyChooseUs = () => {
  return (
    <div className="why-choose-us-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Why Choose Us?" />
        <div className=" mt-8  grid  gap-8 md:grid-cols-2 lg:mt-12 lg:grid-cols-3 ">
          {aspectAddressCardsData.map((cardData) => {
            return (
              <WhyChooseUsCard
                key={cardData.heading}
                image={cardData.image}
                heading={cardData.heading}
                description={cardData.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
