import React from "react";
import {
  dataComparisonAcrossDatabase,
  dataComparisonAcrossCsv,
} from "../../../helper/cardsData/dataComparisonMockData";
import CodeSnippet from "../../../common/codeSnippet/CodeSnippet";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const CodeSnippetEditor = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
       <ComponentHeading heading="Code Snippet"/>
       <div className="lg:mt-12 mt-8">
      <div className=" grid  gap-16  lg:grid-cols-2">
        {/* <div className="mt-8 lg:mx-24 lg:mt-12"> */}
        <CodeSnippet
          language="xml"
          snippetTitle="Data Comparison across csv files "
        >{`${dataComparisonAcrossCsv}`}</CodeSnippet>
        {/* </div> */}
        {/* <div className="mt-8 lg:mx-24 lg:mt-12"> */}
        <CodeSnippet
          language="xml"
          snippetTitle="Data Comparison across database sources"
        >{`${dataComparisonAcrossDatabase}`}</CodeSnippet>
        {/* </div> */}
      </div>
      </div>
    </div>
  );
};

export default CodeSnippetEditor;
