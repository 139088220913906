import React from "react";
import "./UiAutomationHeroSection.css";

export const UiAutomationHeroSection = () => {
  return (
    <div className="data-comparison-hero-section-background">
      <div className="component-padding mx-auto max-w-screen-2xl text-white">
        <p className=" text-center text-3.5xl font-semibold lg:mt-20 lg:text-5xl">
          UI Automation: One Framework, One File
        </p>
{/*         <p className="text-center text-xl mt-1 lg:mt-2">
          One Framework, One File
        </p> */}
        <p className="mt-3 text-center text-lg lg:mb-20 lg:mt-10 lg:text-2xl">
          Step into the future of UI automation with Python and Behave. Our
          robust reporting eliminates manual analysis, providing actionable
          insights for swift decision-making. Python's versatility and Behave's
          framework create a powerful synergy, streamlining your testing
          process.
        </p>
      </div>
    </div>
  );
};

export default UiAutomationHeroSection;
