import React from "react";
import { realNumbersData } from "../../../helper/cardsData/homepageMockData";
import realNumbersUnderlineIcon from "../../../assets/realNumbersUnderlineIcon.svg";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import CountUp from "react-countup";

const Overview = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="Overview" />

      <div className=" mt-8 grid grid-cols-3 gap-5 lg:mt-12 ">
        {realNumbersData.map((item) => {
          return (
            <div key={item.id} className="flex flex-col items-center">
              <p className="font-Lexend text-center text-4xl font-medium text-primaryColor lg:text-4.5xl">
                <CountUp
                  end={item.count}
                  suffix="+"
                  useEasing
                  enableScrollSpy
                  scrollSpyOnce
                />
              </p>
              <img
                src={realNumbersUnderlineIcon}
                alt="real numbers underline "
                className="my-2 lg:my-5"
              />
              <p className="font-Lexend text-center text-sm font-medium  lg:text-xl lg:font-bold">
                {item.heading}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Overview;
