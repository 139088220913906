import React from "react";
import { DataComparisonHeroSection } from "../../components/dataComparisonPage/dataComparisonHeroSection/DataComparisonHeroSection";
import KeyFeature from "../../components/dataComparisonPage/keyFeature/KeyFeature";
// import CodeSnippets from "../../components/dataComparisonPage/codeSnippets/CodeSnippets";
import CodeSnippets from "../../components/dataComparisonPage/codeSnippetEditor/CodeSnippetEditor";
import WhyChooseUs from "../../components/dataComparisonPage/whyChooseUs/WhyChooseUs";

const DataComparisonPage = () => {
  return (
    <>
      <DataComparisonHeroSection />
      <KeyFeature />
      <CodeSnippets />
      <WhyChooseUs />
    </>
  );
};

export default DataComparisonPage;
