import React, { useEffect, useRef, useState } from "react";
import "./ScrollImageTransitionAnimation.css";
import ScrollImageTransitionCard from "./scrollImageTransitionCard/ScrollImageTransitionCard";
import ComponentHeading from "../componentHeading/ComponentHeading";

const ScrollImageTransitionAnimation = ({ heading, cardsData }) => {
  const ref = useRef(null);
  const [blueLineHeight, setBlueLineHeight] = useState(0);

  const handleScroll = () => {
    const rect = ref.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Calculate the scroll percentage based on the element's position and height
    const distanceFromTop = rect.top;
    const scrollPercentage = Math.max(
      0,
      Math.min(
        1,
        (windowHeight * 0.3 - distanceFromTop) / (windowHeight * 0.3),
      ),
    );
    setBlueLineHeight(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    return () => {
      // Cleanup on component unmount
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  return (
    <div className="component-padding mx-auto max-w-screen-2xl ">
      <ComponentHeading heading={heading} />
      <div ref={ref} className="lg:relative lg:my-12 ">
        {/* Blue line with gradient  */}
        <div className="top-gradient-blue-line -mt-12 hidden bg-gradient-to-b from-primaryColor/0 to-primaryColor/100 lg:block" />

        <div className="dotted-line hidden lg:block" />

        {/* Blue line with dynamic height */}
        <div className="blue-line-wrapper hidden lg:block">
          <div
            className="blue-line origin-top  "
            style={{
              transform: `translate(-50%) scaleY(${blueLineHeight})`,
            }}
          />
        </div>

        {/* Cirular element below dynamic blue line */}
        <div className="features-avatar-wrapper hidden lg:block">
          <div className="features-avatar" />
        </div>

        {/* Opacity gradient on the bottom for the dotted line */}
        <div className="bottom-absolute-div -mb-12 hidden  bg-gradient-to-b from-white/0 to-white/100 lg:block" />

        {cardsData.map((item, index) => {
          return (
            <ScrollImageTransitionCard
              imageSource={item.image}
              imageAltText={item.heading}
              heading={item.heading}
              bulletPoints={item.bulletPoints}
              isFirst={index === 0}
              isLast={index === cardsData.length - 1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ScrollImageTransitionAnimation;
