import React from "react";
import "./DataComparisonHeroSection.css";

export const DataComparisonHeroSection = () => {
  return (
    <div className="data-comparison-hero-section-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <p className=" text-center text-3.5xl font-semibold text-white lg:mt-20 lg:text-5xl">
          Data Comparison
        </p>
        <p className="mt-3 text-center text-lg  text-white lg:mb-20 lg:mt-10 lg:text-2xl">
          Welcome to a revolutionary solution that transforms the way you
          compare data across diverse databases and Comma Separated Value (.csv)
          files. Our goal is to enhance your user experience, reduce your time
          investment, and elevate overall efficiency in handling data
          comparisons.
        </p>
      </div>
    </div>
  );
};
