import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import CodeSnippet from "../../../common/codeSnippet/CodeSnippet";
import { oneForAllCodeSnippet } from "../../../helper/cardsData/uiAutomationPageMockData";

const UiAutomationCodeSnippet = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="Code Snippet" />
      <div className="mt-8 lg:mt-12">
        <CodeSnippet
          language="xml"
          snippetTitle="One for all XML – API, Function and Data Comparison Automation"
        >{`${oneForAllCodeSnippet}`}</CodeSnippet>
      </div>
    </div>
  );
};

export default UiAutomationCodeSnippet;
