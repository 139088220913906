import AdvantagesHandlingOfDateFormatsIcon from "../../assets/advantagesHandlingOfDateFormatsIcon.svg";
import AdvantagesIntegrationWithGempypcodeRephaseIcon from "../../assets/advantagesIntegrationWithGempypcodeRephaseIcon.svg";
import AdvantagesMultipleFeaturesForEnhancedComparisonIcon from "../../assets/advantagesMultipleFeaturesForEnhancedComparisonIcon.svg";
import AdvantagesPrimaryKeyApproachIcon from "../../assets/advantagesPrimaryKeyApproachIcon.svg";
import AdvantagesSwiftDataComparisonIcon from "../../assets/advantagesSwiftDataComparisonIcon.svg";
import databaseToDatabaseComparisonIcon from "../../assets/databaseToDatabaseComparisonIcon.svg";
import csvToCsvComparisonIcon from "../../assets/csvToCsvComparisonIcon.svg";
import databaseToCsvComparisonIcon from "../../assets/databaseToCsvComparisonIcon.svg";
export const advantagesCardData = [
  {
    icon: AdvantagesPrimaryKeyApproachIcon,
    cardNumber: "1",
    heading: "Primary Key Approach",
    description: "Get the Gemini ambassador certificate to boost your resume",
  },
  {
    icon: AdvantagesHandlingOfDateFormatsIcon,
    cardNumber: "2",
    heading: "Handling of Date Formats",
    description: "Manages various date formats when comparing databases.",
  },
  {
    icon: AdvantagesSwiftDataComparisonIcon,
    cardNumber: "3",
    heading: "Swift Data Comparison",
    description: "Ensures efficient and quick data comparison processes.",
  },
  {
    icon: AdvantagesMultipleFeaturesForEnhancedComparisonIcon,
    cardNumber: "4",
    heading: "Multiple Features for Enhanced Comparison",
    description:
      "Offers features like skip_column, tolerance, column_map, etc.",
  },
  {
    icon: AdvantagesIntegrationWithGempypcodeRephaseIcon,
    cardNumber: "5",
    heading: "Integration with GemPyp codeRephase",
    description:
      "Integrates seamlessly with GemPyp codeRephase for enhanced functionality.",
  },
];

export const featuresCardData = [
  {
    image: databaseToDatabaseComparisonIcon,
    heading: "Database to Database Comparison",
    bulletPoints: [
      "Supports comparisons between databases of the same type.",
      "Allows cross-database comparisons.",
      "Supports MongoDB, Snowflake, PostgreSQL, and MySQL.",
    ],
  },
  {
    image: csvToCsvComparisonIcon,
    heading: "CSV to CSV Comparison",
    bulletPoints: [
      "Facilitates data comparison between two CSV files.",
      "Enables users to validate and compare tabular data.",
    ],
  },
  {
    image: databaseToCsvComparisonIcon,
    heading: "Database to CSV Comparison",
    bulletPoints: [
      "Empowers users to compare data between databases and CSV files.",
      "Offers flexibility in handling diverse data sources.",
    ],
  },
];
