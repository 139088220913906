import React from "react";
import { keyFeatureCardsData } from "../../../helper/cardsData/dataComparisonMockData";
import KeyFeatureCard from "../../../common/keyFeatureCard/KeyFeatureCard";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { Carousel } from "primereact/carousel";

export const KeyFeature = () => {
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const CarouselKeyFeatureCard = ({ icon, heading, description }) => {
    return (
      <div className="md:p-2">
        <KeyFeatureCard
          icon={icon}
          heading={heading}
          description={description}
          headingClasses="h-12 md:h-auto lg:h-12 "
          descriptionClasses="h-44 md:h-32 lg:h-44 "
        />
      </div>
    );
  };
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="Key Features" />

      <div className="mt-8 lg:mt-12">
        <Carousel
          value={keyFeatureCardsData}
          itemTemplate={CarouselKeyFeatureCard}
          numVisible={3}
          responsiveOptions={responsiveOptions}
        />
      </div>
    </div>
  );
};

export default KeyFeature;
